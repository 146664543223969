export default {
  Home: `home`,
  Login: `login`,
  Gtc: `gtc`,
  Admin: `admin`,
  Kyc: `kyc`,
  Link: `link`,
  NotFound: `not-found`,
  Error: `error`,
};
